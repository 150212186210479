import { DatePicker, Input, Select, Space, TimePicker, Upload } from "antd";
import TextArea from "antd/es/input/TextArea";
import dayjs from 'dayjs';
import Notiflix from "notiflix";
import { useContext, useEffect, useState } from "react";
import { TfiReload } from "react-icons/tfi";
import { store } from "../Helper/Store";
import "./appointmentpage.css";
import PostApiCall from ".././API/POSTAPI";
import Form from "react-bootstrap/Form";
import moment from "moment";
import CalendarTime from "./CalendarTime";
import { Modal } from "react-bootstrap";
import GetApiCall from "../API/GETAPI";
import axios from "axios";
import { PlusOutlined } from "@ant-design/icons";
import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';
import { MdCurrencyRupee } from "react-icons/md";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

export default function Appointmentform({ serviceName, showForm, closeModal }) {
  useEffect(() => {
    generateRandomCaptcha();
    // GetAllBookedSlot();
  }, []);
  // const GetAllBookedSlot = () => {
  //   PostApiCall.postRequest(
  //     {
  //       catCount: "*",
  //       whereClause: "",
  //     },
  //     "GetAllAppointments"
  //   ).then((results) =>
  //     results.json().then((obj) => {
  //       if (results.status === 200 || results.status === 201) {
  //         setBookedSlots(obj.data);
  //       } else {
  //         Notiflix.Notify.failure("Something went wrong!");
  //       }
  //     })
  //   );
  // };

  // const [bookedSlots, setBookedSlots] = useState([]);
  const history = useHistory()
  const { setBookedDetails, setLoggedIn, setcart, setcartamount, setCartItems } = useContext(store)

  const [captcha, setCaptcha] = useState("");
  const [enteredCaptcha, setEnteredCaptcha] = useState("");
  const [name, setName] = useState("");
  const [gender, setGender] = useState("");
  const [maritalStatus, setMaritalStatus] = useState("");
  const [birthPlace, setBirthPlace] = useState("");
  const [mobile, setMobile] = useState("");
  const [dialcode, setDialcode] = useState("");
  const [email, setEmail] = useState("");
  const [selectedTime, setSelectedTime] = useState("");
  const [selectedDate, setSelectedDate] = useState("");
  const [birthDate, setBirthDate] = useState("");
  const [birthTime, setBirthTime] = useState("");
  const [showDOB, setShowDOB] = useState(false);
  const [showTOB, setShowTOB] = useState(false);
  const [showPOB, setShowPOB] = useState(false);

  const [message, setMessage] = useState("");
  const [selectedService, setSelectedService] = useState("");
  const [selectedServiceId, setSelectedServiceId] = useState(null);
  const [currentStatus, setCurrentStatus] = useState("New");
  const [serviceData, setServiceData] = useState([]);
  const [timeInterval, setTimeInterval] = useState("")
  const [vastuArea, setVastuArea] = useState("")
  // states for service price
  const [halfHourprice, setHalfHourPrice] = useState(null);
  const [oneHourprice, setOneHourPrice] = useState(null);
  const [bookingprice, setBookingPrice] = useState(null);
  const [selectedAmount, setSelectedAmount] = useState(null);


  // image upload
  const [fileList, setFileList] = useState([])
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [urlData, setUrlData] = useState([]);
  useEffect(() => {
    GetServiceData();
  }, []);

  const GetServiceData = () => {
    PostApiCall.postRequest(
      {
        catCount: "*",
        whereClause: "",
      },
      "Services"
    ).then((results) =>
      results.json().then((obj1) => {
        if (results.status === 200 || results.status === 201) {
          setServiceData(
            obj1.data.filter((data) => data.fld_status === "Active")
          );
        }
      })
    );
  };

  // Calendar

  const [showSlot, setShowSlot] = useState(false);

  function disabledDate(current) {
    if (current && current.isBefore(moment(), "day")) {
      return true;
    }
  }

  const handleChangeDate = (value) => {
    setSelectedDate(value?.format("DD/MM/YYYY"));
    setShowSlot(true);
  };

  // dialcode phone number
  const handleCountryChange = (value, country) => {
    setMobile(country.inputValue)
    setDialcode("+" + country.country.dialCode)
  };

  // handleChange , when user select service and clear other details if service is changed

  const handleChange = (value, servicePrices, serviceid) => {
    setHalfHourPrice(servicePrices?.fld_price ? servicePrices?.fld_price : null)
    setOneHourPrice(servicePrices?.fld_price_1_hr ? servicePrices?.fld_price_1_hr : null)
    setBookingPrice(servicePrices?.fld_booking_amount ? (servicePrices?.fld_booking_amount, setSelectedAmount(servicePrices?.fld_booking_amount)) : null)
    setSelectedService(value);
    setSelectedServiceId(serviceid);
    clearFormData("serviceChange")
    switch (value) {
      case "Astrology":
        setShowDOB(true)
        setShowPOB(true);
        setShowTOB(true);
        break;
      case "Vastu Shastra":
        setShowDOB(true)
        setShowPOB(false);
        setShowTOB(true);
        break;
      case "Handwriting and Signature Analysis":
        setShowDOB(true)
        setShowPOB(true);
        setShowTOB(true);
        break;
      case "Numerology":
        setShowDOB(true)
        setShowPOB(false);
        setShowTOB(false);
        break;
      case "Palmistry":
        setShowDOB(true)
        setShowPOB(true);
        setShowTOB(true);
        break;
      case "Tarot":
        setShowDOB(false)
        setShowPOB(false);
        setShowTOB(false);
        break;
      case "Reiki Healing":
        setShowDOB(false)
        setShowPOB(false);
        setShowTOB(false);
        break;
      default:
        setShowDOB(false)
        setShowPOB(false);
        setShowTOB(false);
    }
  };

  const handleRegexValidation = (email, phone) => {
    let result;
    if (email) {
      result = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    } else {
      result = /^\d{10}$/.test(phone);
    }
    return result;
  };
  const generateRandomCaptcha = () => {
    const newCaptcha = Math.random().toString(36).slice(2, 7).toUpperCase();
    setCaptcha(newCaptcha);
  };
  function isCaptchaValid(enteredCaptcha) {
    return captcha == enteredCaptcha;
  }



  // emailer html for client 
  const htmlForClient =
    "<!doctype html>" +
    "<html>" +
    "<head>" +
    '<meta charset="utf-8">' +
    "<title>Enquiry</title>" +
    "</head>" +
    "<body style=font-family: Gotham, Helvetica Neue, Helvetica, Arial, sans-serif>" +
    '<div class="wrapper" style="padding: 10px;width: 1000px;margin: auto;border: .5px solid;">' +
    '<div class="container" style="padding: 5px">' +
    '<img style="width: 100px" src="https://store.bechofy.in/images/dbo/ProductImages/820225-BLESSINGS-LOGO.webp" alt="" >' +
    " </div><hr>" +
    '<div class="container">' +
    " <h4>Dear Sir/Mam,</h4>" +
    " <p>You have recieved a message from <b>" +
    "Blessings Astrology" +
    " Ecommerce Website : </b></p>" +
    "</div>" +
    "<br>" +
    '<table style="padding-bottom: 20px">' +
    "<tr>" +
    "<td>Name:</td>" +
    '<td style="padding-left: 110px">' +
    name +
    "</td>" +
    " </tr>" +
    " <tr>" +
    "<td>Gender:</td>" +
    '<td style="padding-left: 110px">' +
    gender +
    "</td>" +
    " </tr>" +
    " <tr>" +
    "<td>Marital Status:</td>" +
    '<td style="padding-left: 110px">' +
    maritalStatus +
    "</td>" +
    " </tr>" +
    (birthDate == ""
      ? ""
      : " <tr>" +
      "<td>Date Of Birth:</td>" +
      '<td style="padding-left: 110px">' +
      birthDate +
      "</td>" +
      " </tr>") +
    (birthTime == ""
      ? ""
      : "<tr>" +
      "<td>Time Of Birth:</td>" +
      '<td style="padding-left: 110px">' +
      birthTime +
      "</td>" +
      " </tr>") +
    (birthPlace == ""
      ? ""
      : " <tr>" +
      "<td>Birth Place:</td>" +
      '<td style="padding-left: 110px">' +
      birthPlace +
      "</td>") +
    " </tr>" +
    " <tr>" +
    "<td>Email:</td>" +
    '<td style="padding-left: 110px">' +
    email +
    " </td>" +
    " </tr>" +
    " <tr>" +
    "<td>Mobile No.:</td>" +
    '<td style="padding-left: 110px">' +
    mobile +
    " </td>" +
    "</tr>" +
    (vastuArea == ""
      ? ""
      : "<tr>" +
      "<td>Vastu Area:</td>" +
      '<td style="padding-left: 110px">' +
      vastuArea +
      " </td>" +
      "</tr>" +
      " <tr>") +
    "<td>Service Name:</td>" +
    '<td style="padding-left: 110px">' +
    selectedService +
    " </td>" +
    "</tr>" +
    " <tr>" +
    "<td>Appointment Date:</td>" +
    '<td style="padding-left: 110px">' +
    selectedDate +
    " </td>" +
    "</tr>" +
    (timeInterval === "" ?
      ""
      : "<tr>" +
      "<td>Appointment Duration:</td>" +
      '<td style="padding-left: 110px">' +
      timeInterval +
      " </td>" +
      "</tr>") +
    (selectedTime == null ?
      ""
      : " <tr>" +
      "<td>Appointment Time:</td>" +
      '<td style="padding-left: 110px">' +
      selectedTime +
      "</td>" +
      "</tr>") +
    (message == ""
      ? ""
      : "<tr>" +
      "<td>Message:</td>" +
      '<td style="padding-left: 110px">' +
      message +
      "</td>" +
      "</tr>") +
    (selectedService === "Handwriting and Signature Analysis"
      ? "<tr>" +
      "<td>Handwriting or Signature Image :</td>" +
      '<td style="padding-left: 110px">' +
      `<img style="width: 100px" src=${urlData[0]?.imageUrl} alt="" >` +
      "</td>" +
      "</tr>"
      : "") +
    (selectedService === "Palmistry"
      ? ""
      : "<tr>" +
      "<td>Palmistry Image :</td>" +
      '<td style="padding-left: 110px;display: flex;">' +
      `<img style="width: 100px;margin-right:30px" src=${urlData[0]?.imageUrl} alt="" >` +
      `<img style="width: 100px" src=${urlData[1]?.imageUrl} alt="" >` +
      "</td>" +
      "</tr>") +
    "</table>" +
    "</div>" +
    "</body>" +
    "</html>"



  // emailer html for user 
  const htmlForUser =
    "<!doctype html>" +
    "<html>" +
    "<head>" +
    '<meta charset="UTF-8">' +
    '<meta name="viewport" content="width=device-width, initial-scale=1.0">' +
    "<title>Message Notification</title>" +
    "</head>" +
    '<body style="font-family: Arial, sans-serif; line-height: 1.6; color: #333; margin: 0; padding: 0; background-color: #f9f9f9;">' +
    '<table style="margin: 0px auto; background-color: #fff;  border-collapse: collapse;width:100%;">' +
    "<tr>" +
    '<td style="background-color: #410f58; color: #fff; padding: 15px; text-align: center; font-size: 18px;">' +
    "<strong>Thank You for Booking</strong>" +
    "</td>" +
    " </tr>" +
    "<tr>" +
    '<td style="padding: 20px 20px 0 20px; ">' +
    '<div style="text-align: center">' +
    '<img src="https://store.bechofy.in/images/dbo/ProductImages/820225-BLESSINGS-LOGO.webp" height="70px">' +
    "</div>" +
    '<p style="margin: 0;">Dear Sir/Mam,</p>' +
    '<p style="margin-top: 10px;">Your consultation request with Dr. Surabhi Bhatnagar has been received. Our team will be in touch shortly to confirm your appointment timings. Below are the details of your request:</p>' +
    '<div style="display: flex; padding: 20px;">' +
    '<div style="background-color: #f8f0fc; color: #333; box-shadow: -1px 0 5px 0 rgba(0, 0, 0, .5); border-radius: 10px; margin:0 auto; width: 100%; max-width: 250px; padding: 20px;">' +
    '<div style="display: flex; gap: 60px; ">' +
    '<strong style="color: #410f58;">Name:</strong>' +
    '<span style="margin-left:auto">' + name + '</span>' +
    "</div>" +
    '<div style="display: flex; gap: 60px; ">' +
    '<strong style="color: #410f58;">Booking Date:</strong>' +
    '<span style="margin-left:auto">' + selectedDate + '</span>' +
    "</div>" +
    '<div style="display: flex; gap: 60px; ">' +
    '<strong style="color: #410f58;">Preferred Slot:</strong>' +
    '<span style="margin-left:auto">' + selectedTime + '</span>' +
    "</div>" +
    '<div style="display: flex; gap: 60px; ">' +
    '<strong style="color: #410f58;">Service:</strong>' +
    '<span style="margin-left:auto">' + selectedService + '</span>' +
    "</div>" +
    '<div style="display: flex; gap: 60px;">' +
    '<strong style="color: #410f58;">Paid Amount:</strong>' +
    '<span style="margin-left:auto">' + selectedAmount + '</span>' +
    "</div>" +
    "</div>" +
    "</div>" +
    '<p>I greatly appreciate your prompt attention to this matter. Your continued trust in my freelance translation services is invaluable, and I want to ensure that our collaboration remains convenient and reliable.</p>' +
    '<p> Thank you for choosing me as your freelance translator, and I look forward to our continued partnership. </p >' +
    '<div style="font-family: Arial, sans-serif; margin: 0; padding: 0; background-color: #f4f4f4; ">' +
    '<div style="border-spacing: 0; background-color: #ffffff;">' +
    "<tr>" +
    '  <table style="border-spacing: 0; background-color: #ffffff; overflow: hidden;text-align: center;width: 100%;">' +
    "<tr>" +
    '<td style="padding: 10px; font-size: 24px; color: #410f58; font-weight: bold;"> Blessings Astrology</td>' +
    "</tr>" +
    "<tr>" +
    '<td style="padding: 0px;">' +
    '<p style="margin: 5px 0;">' +
    '<img src="https://tlr.stripocdn.email/content/guids/CABINET_09e9fe3469e9e38cee45638bc890f8fb7fa30bea0ae9e8d1c37288fc5f1f0d62/images/envelope_8.png" alt="Email Icon" style="height: 20px;">' +
    "surabhibhatnagar@blessingsastrology.com" +
    "</p>" +
    '<a href="tel:98211 55251" style="margin: 5px 0;">' +
    '<img src="https://tlr.stripocdn.email/content/guids/CABINET_09e9fe3469e9e38cee45638bc890f8fb7fa30bea0ae9e8d1c37288fc5f1f0d62/images/mobilebutton_1.png" alt="Phone Icon" style="height: 20px;">' +
    "+91 - 98211 55251" +
    "</a>" +
    "</td>" +
    "</tr>" +
    "<tr>" +
    '<td style="padding: 10px;">' +
    '<a href="https://www.facebook.com/blessings.astrologysolutions?_rdr" style="margin: 0 5px;">' +
    '<img src="https://store.bechofy.in/images/Blessings/ProductReviewImages/Facebook.webp" alt="Facebook" style="height: 30px;">' +
    "</a>" +
    '<a href="https://www.instagram.com/blessings.astrologysolutions/?r=nametag" style="margin: 0 5px;">' +
    '<img src="https://store.bechofy.in/images/Blessings/ProductReviewImages/Instagram.webp" alt="Instagram" style="height: 30px;">' +
    "</a>" +
    '<a href="https://www.youtube.com/channel/UCWOu9AwcqwDxXUa9ZRi7cEQ" style="margin: 0 5px;">' +
    '<img src="https://store.bechofy.in/images/Blessings/ProductReviewImages/Youtube.webp" alt="YouTube" style="height: 30px;">' +
    "</a>" +
    '<a href="https://www.linkedin.com/in/dr-surabhi-bhatnagar-7a470612b/" style="margin: 0 5px;">' +
    '<img src="https://store.bechofy.in/images/Blessings/ProductReviewImages/Linkedin.webp" alt="LinkedIn" style="height: 30px;">' +
    "</a>" +
    "</td>" +
    "</tr>" +
    "<tr>" +
    '<td style="padding: 20px; background-color: #410f58; color: #ffffff; font-size: 14px;">Emaar Palm Gardens, Tower 9 Unit-002, Sector-83, Gurugram - 122 004, Haryana.</td>' +
    "</tr>" +
    "</table>" +
    "</tr>" +
    "</div>" +
    "</tr>" +
    "</table>" +
    "</div>" +
    "</td>" +
    "</tr>" +
    "</table>" +
    "</body>" +
    "</html>"




  // form submition validation
  function handleValidateForm() {
    if (selectedService === "") {
      Notiflix.Notify.failure("Please select a service first.", { zindex: 99999 });
      return;
    }
    if (name === "") {
      Notiflix.Notify.failure("Please enter your name.", { zindex: 99999 });
      return;
    }
    if (gender === "") {
      Notiflix.Notify.failure("Please select your gender", { zindex: 99999 });
      return;
    }
    if (maritalStatus === "") {
      Notiflix.Notify.failure("Please select your martial status", { zindex: 99999 });
      return;
    }
    if (showDOB === true && birthDate === "") {
      Notiflix.Notify.failure("Please select your birth date", { zindex: 99999 });
      return;
    }
    if (showTOB === true && birthTime === "") {
      Notiflix.Notify.failure("Please select your birth time", { zindex: 99999 });
      return;
    }
    if (showPOB === true && birthPlace === "") {
      Notiflix.Notify.failure("Please enter your birth place", { zindex: 99999 });
      return;
    }
    if (selectedService === "Vastu Shastra" && vastuArea === "") {
      Notiflix.Notify.failure("Vastu area is required", { zindex: 99999 });
      return;
    }
    if (mobile === "" || mobile.replace(/\s+/g, '') === dialcode.replace(/\s+/g, '')) {
      Notiflix.Notify.failure("Please enter your phone mobile number", { zindex: 99999 });
      return;
    }
    if (email == "") {
      Notiflix.Notify.failure("Please enter your email address", { zindex: 99999 });
      return;
    } else if (!handleRegexValidation(email, "")) {
      Notiflix.Notify.failure("Invalid email address", { zindex: 99999 });
      return;
    }
    if (selectedService === "Palmistry" && urlData.length !== 2) {
      Notiflix.Notify.failure("Please upload both the front and rear hand images", { zindex: 99999 });
      return;
    }
    if (selectedService === "Handwriting and Signature Analysis" && urlData.length !== 1) {
      Notiflix.Notify.failure(" Please upload handwritting or signature image", { zindex: 99999 });
      return;
    }
    if (selectedDate === "") {
      Notiflix.Notify.failure("Please select appointment date", { zindex: 99999 });
      return;
    }
    if (bookingprice === null && timeInterval === "") {
      Notiflix.Notify.failure("Please select appointment time duration", { zindex: 99999 });
      return;
    }
    if (bookingprice === null && selectedTime === "") {
      Notiflix.Notify.failure("Please select appointment time", { zindex: 99999 });
      return;
    }
    // if (message === "") {
    //   Notiflix.Notify.failure("Message is required", { zindex: 99999 });
    //   return;
    // }
    if (!isCaptchaValid(enteredCaptcha)) {
      Notiflix.Notify.failure("Invalid Captcha", { zindex: 99999 });
      return;
    }
    addGuestLogin();
  }


  function addGuestLogin() {
    Notiflix.Loading.dots("Please wait...", { zindex: 99999 });
    var login = localStorage.getItem("CustomerData");
    var logininfo = JSON.parse(login);
    if (logininfo) {
      onSubmit()
    } else {
      var Order = localStorage.getItem("OrderData");
      var Orderdetails = JSON.parse(Order);
      PostApiCall.postRequest(
        {
          name: name,
          mobile: mobile,
          email: email,
          cartid:
            localStorage?.getItem("OrderData") == null
              ? null
              : Orderdetails[0].order_id,
          signupguest: "Continue",
        },
        "AddGuest"
      ).then((result) =>
        result.json().then((obj) => {
          if (result.status == 200 || result.status == 201) {
            // clearGuestForm();
            localStorage.setItem("CustomerData", JSON.stringify(obj.data));
            // getCartdetails();
            // setLoggedIn(true);
            onSubmit()


          } else {
            Notiflix.Loading.remove();
            Notiflix.Notify.failure(obj.data);
          }
        })
      )
    }
  }

  function onSubmit() {
    // handlePayment(1)
    PostApiCall.postRequest(
      {
        appointment_id: null,
        name: name,
        gender: gender,
        maritalStatus: maritalStatus,
        tob: birthTime !== "" ? birthTime : null,
        dob: birthDate !== "" ? birthDate : null,
        birthPlace: birthPlace,
        mobile: mobile,
        email: email,
        area: vastuArea !== "" ? vastuArea : null,
        time: selectedTime,
        slotduration: timeInterval,
        date: selectedDate,
        service: selectedService,
        image1: urlData.length > 0 ? selectedService === "Handwriting and Signature Analysis" || selectedService === "Palmistry" ? urlData[0].imageUrl : null : null,
        image2: urlData.length == 2 && selectedService === "Palmistry" ? urlData[1].imageUrl : null,
        message: message,
        status: currentStatus,
      },
      "BookAppointment"
    ).then((results) =>
      results.json().then((obj) => {
        if (results.status === 200 || results.status === 201) {
          // clearFormData("submit");
          // Notiflix.Loading.remove();
          handlePayment(selectedAmount, obj.data[0]?.fld_id)
          // console.log(obj.data[0]?.fld_id, obj.data[0]?.fld_id)
          // handlePayment(1, obj.data[0]?.fld_id)

          // closeModal();
          Notiflix.Notify.success("Form Submitted Successfully.", {
            zindex: 99999,
          });
        } else {
          Notiflix.Loading.remove();
          Notiflix.Notify.failure("Something went wrong!", { zindex: 99999 });
        }
      })
    );
  }





  // pay to razorpay
  const handlePayment = async (amount, appointmentId = null) => {
    try {
      // Get user login details from localStorage
      const login = localStorage.getItem("CustomerData");
      const logindetails = JSON.parse(login);

      // Send a request to your API to get the Razorpay order
      const response = await axios.post("https://api.bechofy.in/blessings-api/payment", { amount });
      const { order } = response.data;

      // Set up Razorpay options
      const options = {
        key: process.env.REACT_PAYMENT_KEY || "rzp_live_PDnM4qUQpUe7rE", // Enter the Key ID generated from the Dashboard
        amount, // Amount in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
        currency: "INR",
        name: process.env.REACT_NAME || "Blessings Astrology", // Your business name
        description: "Blessings Astrology",
        image: "",
        order_id: order.id, // Pass the `id` obtained in the response of Step 1
        handler: async (response) => {
          const body = {
            ...response,
          };

          try {
            // Send the payment status to your API
            Notiflix.Loading.dots("Please wait...", { zindex: 99999 });
            const validateRes = await fetch("https://api.bechofy.in/blessings-api/RazorPayPaymentStatus", {
              method: "POST",
              body: JSON.stringify(body),
              headers: {
                "Content-Type": "application/json",
              },
            });

            const jsonRes = await validateRes.json();


            // Send the booking response to your API
            const bookingResponse = await axios.post(
              // "http://localhost:3035/blessings-api/RazorPayBookingResponse", // Change to production URL in production
              "https://api.bechofy.in/blessings-api/RazorPayBookingResponse", // Change to production URL in production
              {
                txnid: jsonRes.status.id,
                amt: jsonRes.status.amount / 100,
                firstname: name,
                serviceid: selectedServiceId,
                mobile: mobile,
                source: jsonRes.status.method,
                status: jsonRes.status.status,
                msg: message,
                customerid: logindetails[0].fld_customerid,
                appointmentid: appointmentId,
              }
            );


            if (bookingResponse.data.message === true) {
              // Redirect to payment success page

              sendMailer()
              setBookedDetails({
                userName: name,
                bookingDate: selectedDate,
                selectedSlot: selectedTime,
                service: selectedService,
                paidAmount: selectedAmount,
              });
              // history.push("/thankyou-for-booking");
            } else {
              Notiflix.Loading.remove();
              // Redirect to payment failure page
              // window.location.href = `/paymentfail/${paymentTrxNum}`;
            }
          } catch (error) {
            console.error("Error fetching payment status:", error);
          }
        },
        modal: {
          ondismiss: function () {
            // closeModal();
            // clearFormData("submit");
            console.log("Payment popup was closed without completing the transaction.");
          }
        },
        prefill: {
          name,
          email,
          contact: mobile, // Provide the customer's phone number for better conversion rates
        },
        theme: {
          color: "#410F58",
        },
      };

      // Dynamically load the Razorpay script
      const script = document.createElement("script");
      script.src = "https://checkout.razorpay.com/v1/checkout.js";
      script.onload = async () => {
        // Razorpay script is loaded, you can now use window.Razorpay
        Notiflix.Loading.remove();
        const rzp = new window.Razorpay(options);
        // rzp.on("close",)
        rzp.open();
      };

      document.head.appendChild(script);
    } catch (error) {
      console.error(error);
    }
  };

  // send mailer to client and user after payment
  const sendMailer = () => {
    // Notiflix.Loading.dots("Please wait...", { zindex: 99999 });
    // handlePayment(1)
    PostApiCall.postRequest(
      {
        email: email,
        mailerHtmlUser: htmlForUser,
        mailerHtmlClient: htmlForClient,
      },
      "BookAppointmentMailer"
    ).then((results) =>
      results.json().then((obj) => {
        if (results.status === 200 || results.status === 201) {
          // clearFormData("submit");
          Notiflix.Loading.remove();
          // handlePayment(selectedAmount)
          closeModal();
          clearFormData("submit");
          history.push("/thankyou-for-booking");
        } else {
          Notiflix.Loading.remove();
          Notiflix.Notify.failure("Something went wrong!", { zindex: 99999 });
        }
      })
    );
  }



  // clear form details after submit
  const clearFormData = (action) => {
    setName("");
    setGender("");
    setVastuArea("")
    setMaritalStatus("");
    setBirthPlace("");
    setMobile("");
    setTimeInterval("")
    setEmail("");
    setSelectedTime("");
    setMessage("");
    setUrlData([])
    setFileList([])
    if (action === "submit") {
      setSelectedService("")
      setBirthTime("")
      setBirthDate("")
      setSelectedDate("");
    }
  }

  // upload button and function for uploading images 

  const handleChangeImage = async ({ file, fileList }) => {
    const updatedFileList = [...fileList];
    const index = updatedFileList.findIndex((item) => item.uid === file.uid);
    if (index !== -1) {
      updatedFileList[index] = { ...file, status: "done" };
      setFileList(updatedFileList);
    }
    if (file.status === "done") {
      updatedFileList[index] = { ...file, status: "done" };
      setFileList(updatedFileList);
    } else if (file.status === "error") {
      updatedFileList[index] = { ...file, status: "error" };
      setFileList(updatedFileList);
    } else if (file.status === "removed") {
      setFileList(updatedFileList.filter((item) => item.uid !== file.uid));
      setUrlData(urlData.filter((item) => item.uid !== file.uid))
    }
  };

  const uploadButton = (
    <button
      style={{
        border: 0,
        background: "none",
      }}
      type="button"
    >
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </button>
  );
  const onaction = async (dat) => {
    const formData = new FormData();
    formData.append("filename", dat.file.name.replace(/ /g, "-"));
    formData.append("foldername", "ProductReviewImages");
    formData.append("file", dat.file);
    await axios
      .post("https://api.bechofy.in/AddImage", formData, {
        headers: {
          Schema: "Blessings",
        },
      })
      .then((res) => {
        setUrlData([...urlData, { uid: dat.file.uid, imageUrl: res.data.image }]);
      })
      .catch((err) => {
        if (err.response) {
          console.log("Error Response:", err.response.data);
          console.log("Error Status:", err.response.status);
          console.log("Error Headers:", err.response.headers);
        } else if (err.request) {
          console.log("Error Request:", err.request);
        } else {
          console.log("Error Message:", err.message);
        }
        console.log("Error Config:", err.config);
      });
  };

  return (
    <>
      <Modal
        show={showForm}
        onHide={closeModal}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        dialogClassName="modal-90w"
        size="xl"
        className="appointment-form-modal p-0"
      >
        <Modal.Header closeButton className="pb-0"></Modal.Header>
        <Modal.Body className="p-lg-0">

          <section className="spacing services-form">
            <h2 className=" mt-3 text-center"> Book An Appointment</h2>
            <p className="text-center">
              Schedule Your Personalized Consultation with Ease{" "}
            </p>
            <div className="container-fluid mb-3">
              <div className="row contact-form mt-3 select-service-box">
                <div className="col-lg-12 mb-4 select-service-option">
                  <div className="mb-2 text-warning">Kindly select a service before entering additional information. <span className="text-warning">*</span></div>
                  {/* <label for="service">Preferred Service <span className="text-danger">*</span></label> */}
                  <Form.Select
                    id="service"
                    aria-label="Default select example"
                    onChange={(e) => {
                      setSelectedAmount(null)
                      const selectedServiceData = serviceData.find((serviceinfo) => serviceinfo.fld_service_name === e.target.value)
                      const servicePrices = JSON.parse(selectedServiceData?.price_info);
                      handleChange(e.target.value, servicePrices, selectedServiceData.fld_id)
                    }}
                    defaultValue={selectedService}
                  >
                    <option value disabled selected>
                      Select Service
                    </option>
                    {serviceData.map((items, index) => (
                      <option value={items.fld_service_name} key={index}>
                        {items.fld_service_name}
                      </option>
                    ))}
                  </Form.Select>
                </div>
                <div className="col-lg-4 mb-4">
                  <label for="name">Name <span className="text-danger">*</span></label>
                  <Input
                    id="name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    disabled={selectedService !== "" ? false : true}
                  />
                </div>
                <div className="col-lg-4 mb-4">
                  <label for="gender">Gender <span className="text-danger">*</span></label>
                  <Form.Select
                    id="gender"
                    value={gender}
                    onChange={(e) => setGender(e.target.value)}
                    defaultValue={gender}
                    disabled={selectedService !== "" ? false : true}
                  >
                    <option value="" disabled >
                      Select Your Gender
                    </option>
                    <option value="Male">
                      Male
                    </option>
                    <option value="Female">
                      Female
                    </option>
                    <option value="Other">
                      Other
                    </option>

                  </Form.Select>
                </div>
                <div className="col-lg-4 mb-4">
                  <label for="maritalStatus">Marital Status <span className="text-danger">*</span></label>
                  <Form.Select
                    id="maritalStatus"
                    value={maritalStatus}
                    onChange={(e) => setMaritalStatus(e.target.value)}
                    disabled={selectedService !== "" ? false : true}
                  >
                    <option value="" disabled>
                      Marital Status
                    </option>
                    <option value="Married">
                      Married
                    </option>
                    <option value="Unmarried">
                      Unmarried
                    </option>

                  </Form.Select>
                </div>
                {showDOB === true
                  &&
                  <div className="col-lg-4 mb-4 date-picker-appionment">
                    <label for="dob">Date Of Birth <span className="text-danger">*</span></label>
                    <DatePicker
                      id="dob"
                      defaultValue={birthDate}
                      className="ant-input css-dev-only-do-not-override-1gwfwyx ant-input-outlined date-picker"
                      format="DD/MM/YYYY"
                      onChange={(value) => setBirthDate(value?.format("DD/MM/YYYY"))}
                      disabled={selectedService !== "" ? false : true}
                      showNow={false}
                      disabledDate={(current) => current && current >= moment().endOf('day')}
                    />
                  </div>
                }
                {showTOB === true &&
                  <div className="col-lg-4 mb-4 date-picker-appionment ">
                    <label for="tob">Time Of Birth <span className="text-danger">*</span></label>
                    <TimePicker id="tob" use12Hours onChange={(value) => setBirthTime(value?.format("HH:mm A"))} showSecond={false} showNow={false} />
                  </div>
                }
                {showPOB === true &&
                  <div className="col-lg-4 mb-4">
                    <label for="pob">Place Of Birth <span className="text-danger">*</span></label>
                    <Input
                      id="pob"
                      value={birthPlace}
                      onChange={(e) => setBirthPlace(e.target.value)}
                    />
                  </div>
                }
                {selectedService === "Vastu Shastra" &&
                  <div className="col-lg-4 mb-4">
                    <label for="vastuarea">Vastu Area <span className="text-danger">*</span></label>
                    <Form.Select
                      id="vastuarea"
                      aria-label="Area"
                      value={vastuArea}
                      onChange={(e) => setVastuArea(e.target.value)}
                    >
                      <option value="" disabled>
                        Select Area
                      </option>
                      <option value="Home">
                        Home
                      </option>
                      <option value="Industrial">
                        Industrial
                      </option>
                      <option value="Office / Shop">
                        Office / Shop
                      </option>

                    </Form.Select>
                  </div>
                }


                <div className="col-lg-4 mb-4">
                  <label for="phone">Phone Number <span className="text-danger">*</span></label>
                  <PhoneInput
                    id="phone"
                    defaultCountry="in"
                    value={mobile}
                    disabled={selectedService !== "" ? false : true}
                    forceDialCode={true}
                    onChange={handleCountryChange}
                  />
                  {/* <Input
                    id="phone"
                    placeholder="Phone no"
                    value={mobile}
                    disabled={selectedService !== "" ? false : true}
                    onChange={(mobile) => {
                      if (mobile.target.value.length <= 10) {
                        setMobile(mobile.target.value);
                      }
                    }}
                  /> */}
                </div>
                <div className="col-lg-4 mb-4">
                  <label for="email">Email Address <span className="text-danger">*</span></label>
                  <Input
                    id="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    disabled={selectedService !== "" ? false : true}
                  />
                </div>

                <div className="col-lg-4 mb-4 date-picker-appionment">
                  <label for="appointmentDate">Select Your Preferred Date <span className="text-danger">*</span></label>

                  <DatePicker
                    id="appointmentDate"
                    className="ant-input css-dev-only-do-not-override-1gwfwyx ant-input-outlined date-picker"
                    format="DD/MM/YYYY"
                    onChange={handleChangeDate}
                    disabledDate={disabledDate}
                    disabled={selectedService !== "" ? false : true}
                  />
                </div>
                {halfHourprice !== null && <>
                  <div className="col-lg-4 mb-4 date-picker-appionment">
                    <label for="interval">Desired Appointment Duration <span className="text-danger">*</span></label>
                    <Form.Select
                      id="interval"
                      value={timeInterval}
                      onChange={(e) => {
                        setTimeInterval(e.target.value)
                        if (e.target.value === "half an hour") {
                          setSelectedAmount(halfHourprice)
                        } else {
                          setSelectedAmount(oneHourprice)
                        }
                      }}
                      disabled={selectedService !== "" ? false : true}
                    >
                      <option value="" disabled>
                        Time
                      </option> <option value="half an hour">
                        Half An Hour
                      </option>
                      {oneHourprice !== null &&
                        <option value="one hour">
                          One Hour
                        </option>}

                    </Form.Select>
                  </div>

                  <div className="col-lg-4 mb-4 date-picker-appionment">
                    <label for="slot">Select Available Time Slot <span className="text-danger">*</span></label>
                    <Form.Select
                      id="slot"
                      // aria-label="Interval"
                      value={selectedTime}
                      onChange={(e) => setSelectedTime(e.target.value)}
                      disabled={selectedService !== "" ? false : true}
                    >
                      <option value="" disabled>
                        Time Slots
                      </option>
                      <option value="09:00 am - 12:00 pm">
                        09:00 am - 12:00 pm
                      </option>
                      <option value="12:00 pm - 03:00 pm">
                        12:00 pm - 03:00 pm
                      </option>
                      <option value="03:00 pm - 06:00 pm">
                        03:00 pm - 06:00 pm
                      </option>

                    </Form.Select>
                  </div></>}
                {selectedService === "Handwriting and Signature Analysis" &&
                  <div>
                    <label for="imageUpload" className="mb-2">Handwriting or Signature Image <span className="text-danger">*</span></label>
                    <Upload
                      id="imageUpload"
                      customRequest={onaction}
                      listType="picture-card"
                      fileList={fileList}
                      onPreview={() => { }}
                      // onPreview={handlePreview}
                      onChange={handleChangeImage}
                    >
                      {fileList.length >= 1 ? null : uploadButton}
                    </Upload>
                  </div>}
                {selectedService === "Palmistry" &&
                  <div>
                    <label className="mb-2" for="imageUpload2">Front Hand and Side Hand Image <span className="text-danger">*</span></label>
                    <Upload
                      id="imageUpload2"
                      customRequest={onaction}
                      listType="picture-card"
                      fileList={fileList}
                      // onPreview={handlePreview}
                      onPreview={() => { }}
                      onChange={handleChangeImage}
                    >
                      {/* at most 8 images can be selected  */}
                      {fileList.length >= 2 ? null : uploadButton}
                    </Upload>
                  </div>}


                <div className="col-12 mb-4 mt-3">
                  <label for="message" className="mb-2">Message</label>
                  <TextArea
                    id="message"
                    rows={5}
                    // placeholder="Message"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    disabled={selectedService !== "" ? false : true}
                  />
                </div>
                <div className="form-group col-lg-4 mb-4 pb-0">
                  <div className="captcha-box">
                    <input
                      type="text"
                      value={captcha}
                      disabled
                      className="form-control"
                    />
                    <div
                      className="captcha"
                      onClick={() => generateRandomCaptcha()}
                    >
                      <TfiReload />
                    </div>
                  </div>
                </div>
                <div className="form-group col-lg-4 mb-4">
                  <input
                    type="text"
                    value={enteredCaptcha}
                    onChange={(e) =>
                      setEnteredCaptcha(e.target.value.toUpperCase())
                    }
                    className="form-control"
                    placeholder="Enter Captcha *"
                  />
                </div>
                <div className="col-12 mb-3 d-lg-flex justify-content-lg-between mt-lg-0 mt-3 book-app-btn-box">
                  {parseInt(selectedAmount).toFixed(0) < 300 ?
                    <p className="my-auto">Kindly make a token payment to book your session. You'll receive a confirmation email, and the full payment will be required before the consultation.</p>
                    : <p className="my-auto">Please proceed with the payment to book your preferred slot, and a confirmation email will follow.</p>
                  }  <div className="d-flex justify-content-end gap-2 read-more">
                    {/* <a
                      className="theme-btn border-0"
                      onClick={sendMailer}
                    onClick={handleValidateForm}
                    >
                      Submit Mail
                    </a> */}
                    <a
                      className="theme-btn border-0"
                      // onClick={handleValidateForm}
                      // onClick={() => handlePayment(1)}
                      onClick={handleValidateForm}
                    >
                      Pay for Booking {selectedAmount !== null && <>{" "}
                        <MdCurrencyRupee />
                        {selectedAmount}</>}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </Modal.Body>
      </Modal>
    </>
  );
}
