import React, { useContext, useState } from 'react';
import { GoogleLogin } from '@react-oauth/google';
import { jwtDecode } from 'jwt-decode';
import Notiflix from 'notiflix';
import moment from 'moment';
import { store } from '../Helper/Store';
import PostApiCall from '../API/POSTAPI';
const GoogleLoginComponent = ({ handleToggleModal }) => {
	const [user, setUser] = useState(null);
	const { setLoggedIn, setcart, setcartamount, setCartItems } = useContext(store)
	const handleLogin = (response) => {
		if (response?.credential) {
			// console.log('Google Login Success:', response);
			const userInfo = jwtDecode(response?.credential);

			Notiflix.Loading.dots();
			var Order = localStorage.getItem("OrderData");
			var Orderdetails = JSON.parse(Order);
			Notiflix.Loading.circle();
			PostApiCall.postRequest(
				{
					name: userInfo.name,
					mobile: null,
					email: userInfo.email,
					cartid:
						localStorage?.getItem("OrderData") == null
							? null
							: Orderdetails[0].order_id,
					signupguest: "Continue",
				},
				"AddGuest"
			).then((result) =>
				result.json().then((obj) => {
					if (result.status == 200 || result.status == 201) {
						// clearGuestForm();
						Notiflix.Notify.success("Login Successfull");
						localStorage.setItem("CustomerData", JSON.stringify(obj.data));
						if (localStorage.getItem("itemdetails")) {
							addToCart();
						} else {
							getCartdetails();
						}
						Notiflix.Loading.remove();
						setLoggedIn(true);
						handleToggleModal();
					} else {
						Notiflix.Loading.remove();
						Notiflix.Notify.failure(obj.data);
					}
				})
			);
			setUser(userInfo);
		}
	};
	const addToCart = () => {
		var login = localStorage.getItem("CustomerData");
		var logindetails = JSON.parse(login);
		var Order = localStorage.getItem("OrderData");
		var Orderdetails = JSON.parse(Order);

		var itemdetail = JSON.parse(localStorage.getItem("itemdetails"));
		PostApiCall.postRequest(
			{
				orderdate: moment().format("lll"),
				itemdetails: `
                                          [
                                          {
                                              "Item":${itemdetail[0]?.Item},
                                              "Quantity":${1}
                                          }
                                          ]
                                          `,
				status: "INCART",
				customerid:
					localStorage.getItem("CustomerData") == null
						? null
						: logindetails[0].fld_customerid,
				createdon: moment().format("lll"),
				updatedon: moment().format("lll"),
				orderid:
					localStorage.getItem("OrderData") == null
						? 0
						: Orderdetails[0].order_id,
				updatedby:
					localStorage.getItem("CustomerData") == null
						? null
						: logindetails[0].fld_userid,
			},
			"AddToCartWebsite"
		).then((result) =>
			result.json().then((obj) => {
				if (result.status == 200 || result.status == 201) {
					Notiflix.Loading.remove();
					Notiflix.Notify.success("Product added to Cart.");
					if (localStorage.getItem("OrderData") == null) {
						localStorage.setItem("OrderData", JSON.stringify(obj.data));
					}
					getCartdetails();
					localStorage.removeItem("itemdetails");
				} else {
					Notiflix.Loading.remove();
					Notiflix.Notify.failure(obj.data);
				}
			})
		);
	};
	const getCartdetails = () => {
		Notiflix.Loading.circle();
		var login = localStorage.getItem("CustomerData");
		var logindetails = JSON.parse(login);
		var Order = localStorage.getItem("OrderData");
		var Orderdetails = JSON.parse(Order);
		PostApiCall.postRequest(
			{
				orderid:
					localStorage.getItem("OrderData") == null
						? null
						: Orderdetails[0].order_id,
				id:
					localStorage.getItem("CustomerData") == null
						? null
						: logindetails[0].fld_customerid,
			},
			"GetCartWebsite"
		)
			.then((res) => res.json())
			.then((res1) => {
				if (res1.data) {
					Notiflix.Loading.remove();

					setcart(res1.data.length);
					setcartamount(res1.data[0]?.fld_cart_amount_preview);
					setCartItems(res1.data);
				}
			});
	};

	const handleFailure = (error) => {
		console.error('Google Login Failed:', error);
	};
	return (
		<>
			<div>
				<GoogleLogin
					onSuccess={handleLogin}
					onError={handleFailure}
					text='continue_with'
					logo_alignment='center'
				/>
			</div>
		</>
	)
}

export default GoogleLoginComponent