import React from "react";

import "./thankyou.css";
import { Helmet } from "react-helmet";




export default function thankyoupage() {
    return (
        <>
            <Helmet>

                <title>
                    Weekly Horoscope Prediction By Dr. Surabhi Bhatnagar
                </title>
                <meta
                    name="description"
                    content="Blessings Astrology is the perfect destination for anyone seeking guidance in their life, whether it's love, career, or life in general."
                />
                <meta name="keywords" content="Horoscope Prediction, Aries horoscope, Taurus horoscope, taurus weekly horoscope, Gemini horoscope, Cancer horoscope, weekly Leo horoscope, Virgo horoscope, virgo weekly horoscope, weekly Libra horoscope" />

                <link rel="canonical" href="https://blessingsastrology.com/horoscope-prediction" />
                <meta property="og:locale" content="en_US" />
                <meta property="og:site_name" content="Blessings Astrology" />
                <meta property="og:title" content="Weekly Horoscope Prediction By Dr. Surabhi Bhatnagar" />
                <meta property="og:description" content="Blessings Astrology is the perfect destination for anyone seeking guidance in their life, whether it's love, career, or life in general." />
                <meta
                    property="og:url"
                    content="https://blessingsastrology.com/horoscope-prediction"
                />
                <meta
                    property="og:image"
                    content="https://blessingsastrology.com/static/media/horoscopebg.dc48382d7221fd269388.png"
                />
                <meta
                    name="twitter:title"
                    content="Weekly Horoscope Prediction By Dr. Surabhi Bhatnagar"
                />
                <meta
                    name="twitter:description"
                    content="Predict your weekly Horoscope By Dr. Surabhi Bhatnagar - Best Astrologer in India, Check your Moon and Ascendant Signs. Aries Horoscope Prediction."
                />
                <meta
                    name="twitter:image"
                    content="https://blessingsastrology.com/static/media/horoscopebg.dc48382d7221fd269388.png"
                />
            </Helmet>
            <section className="inner-section horoscope-page">


                <section className="thankyou-section bg-light">
                    <div className="container">
                        <div className="row">

                            <div className="col-md-6 mx-auto">
                                <div className="service-card text-center  ">
                                    <div className="icon-wrapper mb-3 ">
                                    </div>
                                    <h3>Thank You !</h3>
                                    <p className=" ">
                                        Thank you for choosing Blessings Astrology!
                                        Your request has been successfully received, and our expert astrologer is diligently working to provide you with a personalized and insightful response.
                                    </p>
                                    <div className="d-flex align-items-center justify-content-center">
                                        <a href="/" className="button button-2">Go To Home</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </section>
        </>
    );
}