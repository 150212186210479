import React, { useContext, useEffect, useState } from "react";
// import Menu from "./Header";
// import Footer from "./Footer";
import { AES } from "crypto-js";
import Notiflix from "notiflix";
import PaymentSuccessImg from "../img/PaymentSuccess/paymentsuccess.png";
import PostApiCall from "../API/POSTAPI";
// import Order_Success from "./assets/img/CodOrder/CodPaymentSuccessful.png";
// import 'react-calendar/dist/Calendar.css';
// import 'react-date-picker/dist/DatePicker.css';
import { Link, useParams } from "react-router-dom";
import { store } from "../Helper/Store";
import GetApiCall from "../API/GETAPI";

export default function PaymentSuccess() {
	const [mainOrder, setMainOrder] = useState([]);
	const [paymentMode, setPaymentMode] = useState("");
	const [orderId, setOrderId] = useState(null);
	const { setcart, clientData } = useContext(store);
	const { txnid } = useParams();
	var mobile = clientData[0]?.mobile;
	var facebook = clientData[0]?.facebook;
	var instagram = clientData[0]?.instagram;
	var linkedin = clientData[0]?.linkedin;
	var pintrest = clientData[0]?.pintrest;
	var twitter = clientData[0]?.twitter;
	var youtube = clientData[0]?.youtube;

	useEffect(() => {
		Notiflix.Loading.init({
			svgColor: "#0f3d74",
		});
		const login = localStorage.getItem("CustomerData");
		const logindetails = JSON.parse(login);
		var cartId = 0;
		// if (localStorage.getItem("OrderData") !== null) {
		// 	cartId = Orderdetails[0].order_id;
		// }

		Notiflix.Loading.circle();

		GetApiCall.getRequest("GetSiteSettingsV1").then((results) =>
			results.json().then((obj1) => {
				if (results.status == 200 || results.status == 201) {
					const mobileno = obj1.data[0].mobile;
					PostApiCall.postRequest(
						{
							whereClause: `where  fld_payment_trx_num='${txnid}'`,
						},
						"GetWebsiteOrderDetails"
					).then((results1) =>
						results1.json().then((obj) => {
							if (results1.status == 200 || results1.status == 201) {
								PostApiCall.postRequest(
									{
										trxid: txnid,
										orderid: obj.data[0]?.fld_order_id,
									},
									"GetCustomerOrderForm"
								).then((results) =>
									results.json().then((obj1) => {
										if (results.status == 200 || results.status == 201) {
											console.log(obj1.data);

											let items = [];
											let formattedAddress = "";
											let customerordernumber = "";

											//  Items List
											items = obj1.data.map((item) => ({
												name: item.item_name,
												variant: item.variant_name,
												quantity: item.fld_quantity,
												price: item.fld_selling_price,
												image: item.variant_image.replace(/ /g, "%20"),
											}));

											// For client shipping address last index
											const lastIndex = obj1.data.length - 1;
											const customershippingplace =
												obj1.data[lastIndex].fld_shipping_address_all;

											const formattedAddresstest = customershippingplace
												.replace(/<br\s*\/?>/gi, "\n")
												.replace(/<\/?span[^>]*>|<\/?b>/g, "")
												.replace(/<[^>]+>/g, "");

											formattedAddress = formattedAddresstest.replace(
												/\n/g,
												"<br />"
											);
											customerordernumber =
												obj1.data[lastIndex].fld_orderNumber;

											if (obj.data.length) {
												try {
													const paymentMode = obj.data[0]?.Payment_Mode;
													const currentOrderId = obj.data[0]?.fld_order_id;
													setOrderId(obj.data[0]?.fld_order_id);
													setMainOrder(obj.data);
													SendMailers(
														obj.data[0]?.Total_Inclusive_Tax,
														paymentMode,
														currentOrderId,
														mobileno,
														items,
														formattedAddress,
														customerordernumber
													); // get the item amount and send it to the mailer
												} catch (error) {
													console.log(error);
												}
											}
										}
									})
								);

								Notiflix.Loading.remove();
							}
						})
					);
				}
			})
		);
	}, []);

	// send payment success mail
	const SendMailers = (amount,
		selectedPaymentMode,
		currentOrderId,
		mobileno,
		productsItems,
		formattedAddress,
		customerordernumber) => {
		Notiflix.Loading.dots();
		var Order = localStorage.getItem("OrderData");
		var login = localStorage.getItem("CustomerData");
		var Orderdetails = JSON.parse(Order);
		var orderid = 0;
		if (localStorage.getItem("OrderData") !== null) {
			orderid = Orderdetails[0].order_id;
		}
		var logindetails = JSON.parse(login);
		PostApiCall.postRequest(
			{
				ordernumber: txnid,
				customername: logindetails[0].fld_name,
				customeremail: logindetails[0].fld_email,
				amount: amount,
				mobile: mobileno,
				orderid: currentOrderId,
				instagram: clientData[0]?.instagram,
				paymentmode: selectedPaymentMode,

				// Add new details for mailer
				products: productsItems,
				shippingaddress: formattedAddress,
				customerordernumber: customerordernumber,
			},
			"CustomerOrderSuccessMailer"
		).then((results2) =>
			results2.json().then((obj2) => {
				if (results2.status == 200 || results2.status == 201) {
				}
			})
		);
		// if (orderid) {
		PostApiCall.postRequest(
			{
				cartID: orderid,
			},
			"PurgeCart"
		).then((result) =>
			result.json().then((obj3) => {
				if (result.status == 200 || result.status == 201) {
					setcart(0);
					localStorage.removeItem("OrderData")
				} else {
					Notiflix.Notify.failure("Not able to process data");
				}
			})
		);
		// }
		Notiflix.Loading.remove();
	};
	const id = txnid;
	const secretKey = "mel#4321!";
	const encryptedID = AES.encrypt(
		JSON.stringify(id.toString()),
		secretKey
	).toString();
	const urlSafeBase64 = encryptedID
		.replace(/\//g, "-")
		.toString()
		.replace("?", "bechofy");
	return (
		<div>
			{/* <Menu></Menu> */}
			<div className="container">
				<div className="row blank-section marginbtm-240">
					<div className="col-md-12 d-flex align-items-center justify-content-center my-5">
						<div className="payment-box text-center">
							<img
								src={PaymentSuccessImg}
								className="paymentFail img-fluid"
								alt="payment success"
								width="400px"></img>
							<div className="mb-3 gap-2 d-flex align-items-center justify-content-center pl-lg-5">
								<Link
									to={`/view-invoice-form/${orderId}/${urlSafeBase64}`}
									className="theme-btn w-auto border-0">
									View order
								</Link>
								<Link
									className="theme-btn w-auto border-0"
									to="/">
									Continue Shopping
								</Link>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

