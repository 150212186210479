import React, { useContext } from "react";
import "./HomeBook.css";
import book from "../../img/book-cover.jpg";
import { MdCurrencyRupee } from "react-icons/md";
import { FaShoppingBag } from "react-icons/fa";
import { Link } from "react-router-dom";
import image from "../../img/surbhi-book.png";
import { store } from "../../Helper/Store";
import AddToCartQty from "../../AddToCart/AddToCartQty";
import AddToCart from "../../AddToCart/AddToCart";
import { Skeleton } from "antd";
import Parser from "html-react-parser";
export default function HomeBooks({ booksData }) {
  const { cartItems } = useContext(store);
  return (
    <>
      <section className=" spacing mb-4 mobile-spacing mt-lg-3">
        <div className="container-fluid py-lg-4 book-section">
          <div className="row py-4">
            <div className="col-lg-6 d-none d-lg-block">
              <img src={image} className="img-fluid book-image"></img>
            </div>
            <div className="col-lg-6">
              <div className="row">
                <div className="col-12 main-heading text-lg-start text-center mb-4">
                  <h2 className="mb-0">Books By Dr. Surabhi Bhatnagar</h2>
                  <p>Expand Your Knowledge with Our Astrology Books</p>
                </div>
                {booksData.length > 0 ? (
                  <>
                    {booksData.map((data, index) => {
                      const isInCart = cartItems.find(
                        (val) => val.fld_variantid === data.fld_variantid
                      );
                      return (
                        <div className="mb-4 col-md-6 col-lg-12" key={index}>
                          <div className="book-card p-0 m-0" >
                            <div className="row align-items-center">
                              <div className="col-lg-6">
                                <div className="image-book-card">
                                  <Link
                                    to={`/p/${data.fld_route}`}
                                  >
                                    <img
                                      src={data.VariantImage}
                                      className="img-fluid mb-lg-0"
                                      alt={data.fld_itemname}
                                    />
                                  </Link>
                                </div>
                              </div>
                              <div className="col-lg-6 ">
                                <div className="content-book my-3">
                                  <div>
                                    <h4>
                                      <Link
                                        to={`/p/${data.fld_route}`}
                                      >
                                        {data.fld_variantname}
                                      </Link>{" "}
                                    </h4>
                                    <p className="mb-2">Vastu for HarMONEY</p>
                                    <p>
                                      {Parser(
                                        ("" + data.fld_short_description + "")
                                          .replace(/font-family/g, "")
                                          .replace(/<br\/?>/gi, " ")
                                      )}
                                    </p>
                                  </div>
                                  <div className="d-flex align-items-end justify-content-between">
                                    <div className="rupee">
                                      <span>
                                        <MdCurrencyRupee />


                                        {data.fld_website_discount_price}
                                      </span>
                                      <del>
                                        {data.fld_website_discount_price ===
                                          data.fld_mrp ? (
                                          ""
                                        ) : (
                                          <>
                                            <MdCurrencyRupee />{" "}
                                            {data.fld_mrp}
                                          </>
                                        )}
                                      </del>
                                    </div>

                                    <div className="btn-box content mx-lg-3">
                                      {isInCart ? (
                                        <AddToCartQty
                                          itemid={data?.fld_variantid}
                                          quantity={isInCart.fld_quantity}
                                          cartlineid={isInCart.fld_cart_dtl_id}
                                        />
                                      ) : (
                                        <span className="theme-btn cursor-pointer">
                                          <AddToCart
                                            itemid={data?.fld_variantid}
                                          />
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </>
                ) : (
                  <>
                    <div className="mb-4">
                      <div className="book-card p-0 m-0">
                        <div className="row">
                          <div className="col-lg-6">
                            <div className="image-book-card h-100">
                              <Skeleton.Image
                                className="img-fluid mb-lg-0 w-100 h-100"
                                alt="Blessing Products"
                              />
                            </div>
                          </div>
                          <div className="col-lg-6 ">
                            <div className="content-book my-3">
                              <div>
                                <p className="mb-2">
                                  <Skeleton.Input />
                                </p>
                                <p className="mb-2">
                                  <Skeleton.Input />
                                </p>
                                <p>
                                  <Skeleton.Input />
                                </p>
                              </div>
                              <div className="d-flex align-items-end justify-content-between">
                                <div className="rupee">
                                  <span>
                                    <Skeleton.Input />
                                  </span>
                                </div>

                                <div className="btn-box content mx-3">
                                  <Skeleton.Button />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="mb-4">
                      <div className="book-card p-0 m-0">
                        <div className="row">
                          <div className="col-lg-6">
                            <div className="image-book-card h-100">
                              <Skeleton.Image
                                className="img-fluid mb-lg-0 w-100 h-100"
                                alt="Blessing Products"
                              />
                            </div>
                          </div>
                          <div className="col-lg-6 ">
                            <div className="content-book my-3">
                              <div>
                                <p className="mb-2">
                                  <Skeleton.Input />
                                </p>
                                <p className="mb-2">
                                  <Skeleton.Input />
                                </p>
                                <p>
                                  <Skeleton.Input />
                                </p>
                              </div>
                              <div className="d-flex align-items-end justify-content-between">
                                <div className="rupee">
                                  <span>
                                    <Skeleton.Input />
                                  </span>
                                </div>

                                <div className="btn-box content mx-3">
                                  <Skeleton.Button />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
              {/* <div className="">

                                <div className="book-card p-0 m-0">
                                    <div className="row align-items-center">
                                        <div className="col-lg-6">
                                            <div className="image-book-card">
                                                <Link to="">
                                                    <img src={book} className="img-fluid mb-lg-0" alt="Blessing Products" />
                                                </Link>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 ">
                                            <div className="content-book my-3">
                                                <div>
                                                    <h4><Link to="">Sacred Spaces</Link> </h4>
                                                    <p className="mb-2">
                                                        Vastu for HarMONEY
                                                    </p>
                                                    <p>This book explains Vastu as a science emphasizing its similarity and synchronization with the rules and laws of nature which is very important...



                                                    </p>
                                                </div>
                                                <div className="d-flex align-items-end justify-content-between">
                                                    <div className="rupee">
                                                        <span>
                                                            <MdCurrencyRupee />

                                                            100
                                                        </span>
                                                        <del>
                                                            <MdCurrencyRupee />

                                                            150
                                                        </del>

                                                    </div>
                                                    <div className="btn-box content mx-3">
                                                        <a href="/" className="add-btn-mobile"><FaShoppingBag className="bag-cart" />Add to cart</a>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                    </div>
                                </div>

                            </div> */}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
