import React, { useEffect, useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import banner from "../../img/Home-banner.jpg";
import bannerMobile from "../../img/bannerMobile.png";
// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";
import image from "../../img/Why-Choose-Us-icons/IAF-logo.jpg"

import "./carousel.css";
import HomeBooks from "../HomeBooks/HomeBooks";
import { Skeleton } from "antd";

// import required modules
import { Autoplay, EffectFade, Navigation, Pagination } from "swiper";
import GetApiCall from "../../API/GETAPI";
import PostApiCall from "../../API/POSTAPI";
import Appointmentform from "../../appointmentpage/appointmentform";
function Carousel({ mainBanners }) {

  const [serviceTitle, setServiceTitle] = useState("")
  const [showForm, setShowForm] = useState(false);
  const showModal = () => {
    setShowForm(true);
  };

  const closeModal = () => {
    setShowForm(false);
  };

  return (
    <>

      {mainBanners.length > 0 ? (
        <Swiper
          pagination={{
            dynamicBullets: true,
            clickable: true,
          }}
          loop={true}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          modules={[Autoplay, Pagination]}
          className="mySwiper home-banner-slider"
        >
          <SwiperSlide >

            <div className="homebanner">
              <img
                alt="Banner"
                // src={window.innerWidth > 568 ? mainBanners[0]?.image?.split("$")[0]?.split("#")[0] : mainBanners[0]?.image?.split("$")[1]?.split("#")[0]}
                src={window.innerWidth > 992 ? banner : bannerMobile}
                className="img-fluid"
              />

              <div className="spacing">
                <div className="content-banner px-3 main-heading text-lg-start text-center mt-md-3">
                  {/* <h1>Begin Your Journey to Success <br></br>with Expert Astrology and Vastu Guidance 






                  </h1> */}
                  <h1>Transform your life with the guidance of Dr. Surabhi Bhatnagar - A renowned Astrology & Vastu Expert, Palmist, Numerologist, and Author










                  </h1>
                  {/* <div className="read-more d-flex">
                    <img src={image} className="img-fluid logo-rms"
                    ></img>
                    <p className="ms-4 m-lg-4 m-2 fs-2 text-start">LifeTime Member of IAF





                    </p>
                  </div>
                  <div>
                    <p className="fs-1 text-start">(Association with ICRT,USA)</p>
                  </div> */}
                </div>
              </div>


            </div>
          </SwiperSlide>

        </Swiper>
      ) : (
        <div className="main-slider-skeleton">
          <Skeleton.Image className="w-100 h-100 img-fluid home-slider-skeleton" />
        </div>
      )}
      {showForm && <Appointmentform serviceName={serviceTitle} showForm={showForm} closeModal={closeModal} />}


    </>
  );
}
export default Carousel;
