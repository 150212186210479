import { MdCurrencyRupee } from "react-icons/md";
import ThankyouAppointmentIcon from "../img/thankyuForBooking.png";
import "./ThankyouAppointmentPage.css";
import { Link } from "react-router-dom";
import { useContext } from "react";
import { store } from "../Helper/Store";
export default function ThankyouAppointmentPage() {
  const { bookedDetails } = useContext(store)

  return (
    <section className="spacing pb-4 blank-section thankyou-appo-page mt-lg-5 pt-5">
      <div className="container-fluid mt-lg-4">
        <div className="row">
          <div className="col-lg-8 mx-auto">
            <div>
              <img
                src={ThankyouAppointmentIcon}
                alt="Appointment Booked"
                className="img-fluid"
              />
            </div>
            <div>
              <h1>Thank You for Booking</h1>
              <p>
                Your consultation request with Dr. Surabhi Bhatnagar has been
                received. Our team will be in touch shortly to confirm your
                appointment timings. Below are the details of your request:
              </p>
            </div>
            <div className="booking-detail-table">
              <div className="bd-box">
                <span>Name :</span>
                <p>{bookedDetails.userName}</p>
              </div>
              <div className="bd-box">
                <span>Booking Date :</span>
                <p>{bookedDetails.bookingDate}</p>
              </div>
              {bookedDetails.selectedSlot !== "" && <div className="bd-box">
                <span>Preferred Slot :</span>
                <p>{bookedDetails.selectedSlot}</p>
              </div>}
              <div className="bd-box">
                <span>Service :</span>
                <p>
                  {bookedDetails.service}(Paid : <MdCurrencyRupee />{" "}
                  {bookedDetails.paidAmount})
                </p>
              </div>
            </div>
            <div>
              <div className="read-more">
                <Link className="d-inline-block" to="/">
                  {" "}
                  Go to Home
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
