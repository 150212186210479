import React, { useContext, useEffect, useState } from "react";

import { Helmet } from "react-helmet";
import "./category.css";
import GetApiCall from "../API/GETAPI";
import PostApiCall from "../API/POSTAPI";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { Skeleton } from "antd";
import { store } from "../Helper/Store";

// import bracelet from "../img/bracelet-category.png"
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";
// import { Skeleton } from "antd";

export default function Homecategorypage() {
  // const [apiData, setApiData] = useState([]);
  const { homeCategory } = useContext(store);

  // useEffect(() => {
  //   GetProductVertical();
  // }, []);
  // const GetProductVertical = () => {
  //   PostApiCall.postRequest(
  //     {
  //       catCount: "*",
  //     },
  //     "GetProductVertical"
  //   ).then((results) =>
  //     results.json().then((obj1) => {
  //       if (results.status === 200 || results.status === 201) {
  //         setApiData(obj1.data);
  //       }
  //     })
  //   );
  // };
  return (
    <>
      {homeCategory.length > 0 ? (
        <section className="inner-section spacing products-page main-product-page mt-lg-4">
          <div className="container-fluid pt-4 pt-lg-0 pb-lg-4">
            <div className="row">
              <div className="text-center main-heading  col-12 text-center t-head mb-lg-4">
                <h2 className="mb-0">Healing Solutions</h2>
                <p>Protection Charms and Energy Shields</p>
              </div>

              <Swiper
                slidesPerView={2}
                spaceBetween={15}
                loop={true}
                pagination={{
                  clickable: true,
                }}
                breakpoints={{
                  640: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                  },
                  768: {
                    slidesPerView: 3,
                    spaceBetween: 40,
                  },
                  1024: {
                    slidesPerView: 5,
                    spaceBetween: 30,
                  },
                }}
                autoplay={{
                  delay: 5000,
                  pauseOnMouseEnter: true,
                  disableOnInteraction: false,
                }}
                modules={[Autoplay, Pagination]}
                className="mySwiper testimonial-swiper home-gallery-swiper pt-2"
              >
                {homeCategory?.map((items, index) => (
                  <>
                    <SwiperSlide key={index}>
                      <div>
                        <Link
                          to={`/ct/${items.fld_route}`}
                        >
                          <img
                            src={items.fld_image}
                            className="img-fluid "
                            alt={items.fld_verticlename}
                          />
                        </Link>
                      </div>
                    </SwiperSlide>
                  </>
                ))}
              </Swiper>
            </div>
          </div>
        </section>
      ) : (
        <section className="inner-section spacing products-page main-product-page mt-lg-4">
          <div className="container-fluid pt-4 pt-lg-0 pb-lg-4">
            <div className="row">
              <div className="text-center main-heading  col-12 text-center t-head mb-lg-2">
                <p>
                  <Skeleton.Input />
                </p>
              </div>
              <div className="col-6 col-lg-3">
                <div className="c-card">
                  <div className="c-img">
                    <Skeleton.Image
                      className="img-fluid w-100 h-100"
                      alt="Blessing Products"
                    />
                  </div>
                </div>
              </div>
              <div className="col-6 col-lg-3">
                <div className="c-card">
                  <div className="c-img">
                    <Skeleton.Image
                      className="img-fluid w-100 h-100"
                      alt="Blessing Products"
                    />
                  </div>
                </div>
              </div>
              <div className="col-6 col-lg-3">
                <div className="c-card">
                  <div className="c-img">
                    <Skeleton.Image
                      className="img-fluid w-100 h-100"
                      alt="Blessing Products"
                    />
                  </div>
                </div>
              </div>
              <div className="col-6 col-lg-3">
                <div className="c-card">
                  <div className="c-img">
                    <Skeleton.Image
                      className="img-fluid w-100 h-100"
                      alt="Blessing Products"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
}
