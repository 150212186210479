import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { AiFillInstagram } from "react-icons/ai";
import {
  FaFacebookF,
  FaLinkedinIn,
  FaPinterestP,
  FaWhatsapp,
  FaYoutube,
} from "react-icons/fa";
// import { FaXTwitter } from "react-icons/fa6";
import { IoCall, IoMail } from "react-icons/io5";
import { Link } from "react-router-dom";
import amex from "../img/payements-icons/amex.svg";
import bhim from "../img/payements-icons/bhim.svg";
import gpay from "../img/payements-icons/gpay.svg";
import mastercard from "../img/payements-icons/mastercard.svg";
import paytm from "../img/payements-icons/paytm.svg";
import phonepe from "../img/payements-icons/phonepe.svg";
import rupay from "../img/payements-icons/rupay.svg";
import upi from "../img/payements-icons/upi.svg";
import visa from "../img/payements-icons/visa.svg";
import { store } from "../Helper/Store";
import "./footer.css";
import ConsultForm from "../home/ConsultForm/ConsultForm";
import Modal from "react-bootstrap/Modal";

import { FaFacebookSquare } from "react-icons/fa";
import { FaInstagramSquare } from "react-icons/fa";
import { FaYoutubeSquare } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import { FiPhoneCall } from "react-icons/fi";
import { IoMdMail } from "react-icons/io";
import { FaWhatsappSquare } from "react-icons/fa";
import logo from "../img/newlogo.png";
import Appointmentform from "../appointmentpage/appointmentform";
import PostApiCall from "../API/POSTAPI";

export default function Footer({ }) {
  const { staticPages, siteHeader, homeCategory } = useContext(store);
  const [serviceData, setServiceData] = useState([]);
  useEffect(() => {
    GetServiceData();
  }, []);

  const GetServiceData = () => {
    PostApiCall.postRequest(
      {
        catCount: "*",
        whereClause: "",
      },
      "Services"
    ).then((results) =>
      results.json().then((obj1) => {
        if (results.status === 200 || results.status === 201) {
          setServiceData(
            obj1.data.filter((items) => items.fld_status === "Active")
          );
        }
      })
    );
  };

  const [atBottom, setAtBottom] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [serviceTitle, setServiceTitle] = useState("");
  const showModal = (items) => {
    setShowForm(true);
  };
  const handleScroll = () => {
    const isBottom =
      window.innerHeight + window.scrollY >=
      document.documentElement.scrollHeight;
    setAtBottom(isBottom);
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const closeModal = () => {
    setShowForm(false);
  };
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      <footer className="section-spacing-x section-spacing-x-mobile spacing">
        <div className="container-fluid pt-4 pb-3 border-bottom border-white">
          <div className="row">
            <div className="col-lg-4 col-md-8 order-lg-1 order-2">
              <div className="row">
                <div className="col-6 mt-lg-0 mt-2">
                  <ul className="ul-li">
                    <h2 className="main-heading">Useful Link</h2>
                    <li>
                      <a href="/faq">FAQ</a>
                    </li>
                    <li>
                      <a href="/journey-of-excellence">Journey of Excellence</a>
                    </li>
                    <li>
                      <a href="/podcast">Podcast</a>
                    </li>
                    <li>
                      <a href="/contact-us"> Contact Us</a>
                    </li>
                  </ul>
                </div>

                <div className=" col-6 mt-lg-0 mt-2 mb-lg-0 mb-4">
                  <ul className="ul-li">
                    <h2 className="main-heading">Shop Online</h2>
                    {homeCategory.map((items, index) => (
                      <li key={index}>
                        <Link
                          to={`/ct/${items.fld_route}`}
                        >
                          {items.fld_name}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-4 text-lg-center order-lg-2 order-1">
              <div className="footer-logo text-center mt-lg-4 mt-2">
                <img src={logo} className="img-fluid"></img>
              </div>
              <div className="fs-3 text-center mt-4">
                <a
                  className="ms-lg-2"
                  href="https://m.facebook.com/blessings.astrologysolutions?_rdr"
                  target={"_blank"}
                >
                  <FaFacebookSquare />
                </a>
                <a
                  className="ms-2"
                  href="https://www.instagram.com/blessings.astrologysolutions/?r=nametag"
                  target={"_blank"}
                >
                  <FaInstagramSquare />
                </a>
                <a
                  className="ms-2"
                  href="https://youtube.com/channel/UCWOu9AwcqwDxXUa9ZRi7cEQ"
                  target={"_blank"}
                >
                  <FaYoutubeSquare />
                </a>
                <a
                  className="ms-2"
                  href="https://www.linkedin.com/in/dr-surabhi-bhatnagar-7a470612b/"
                  target={"_blank"}
                >
                  <FaLinkedin />
                </a>
              </div>
              <div className="d-flex gap-2 flex-column my-2 text-center">
                <div className="number">
                  <a href="tel:+919821155251">
                    <IoCall className="fs-3" /> +91 98211 55251
                    {/* <span className="d-none d-lg-inline ms-2"></span> */}
                  </a>
                </div>
                <a
                  href="mailto:surabhibhatnagar@blessingsastrology.com
"
                  className="ms-lg-2 email"
                >
                  <IoMail className="me-2 fs-3" />
                  surabhibhatnagar@blessingsastrology.com
                </a>
              </div>
            </div>
            <div className="col-lg-4 order-3 d-none d-lg-block">
              <div className="row">
                <div className="col-lg-6 col-6 col-md-4">
                  <ul className="ul-li text-lg-end">
                    <h2 className="main-heading">Services</h2>
                    {serviceData?.sort((a, b) => a.fld_id - b.fld_id).map((items, index) => (
                      <li key={index}>
                        <Link
                          to={`/service/${items.fld_route}`}

                        >
                          {items.fld_service_name}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="col-lg-6 col-6 col-md-12">
                  <ul className="ul-li text-lg-end">
                    <h2 className="main-heading">Policies</h2>
                    {staticPages.map((link) => (
                      <li key={link.fld_id}>
                        <Link to={link.fld_route}>{link.fld_page_name}</Link>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-6 col-md-4 d-lg-none order-3">
              <ul className="ul-li text-lg-end">
                <h2 className="main-heading">Services</h2>
                {serviceData?.slice(0, 4)?.map((items, index) => (
                  <li key={index}>
                    <Link
                      to={`/service/${items?.fld_route}`}
                    >
                      {items.fld_service_name}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
            <div className="col-lg-6 col-6 col-md-12 d-lg-none order-4">
              <ul className="ul-li text-lg-end">
                <h2 className="main-heading">Policies</h2>
                {staticPages.map((link) => (
                  <li key={link.fld_id}>
                    <Link to={link.fld_route}>{link.fld_page_name}</Link>
                  </li>
                ))}
              </ul>
            </div>
            <div className="col-12 d-flex align-items-lg-center justify-content-center order-4">
              <ul className="payments-icons d-flex mt-3">
                <li>
                  <img alt="" src={mastercard} />
                </li>
                <li>
                  <img alt="" src={visa} />
                </li>
                <li>
                  <img alt="" src={amex} />
                </li>
                <li>
                  <img alt="" src={bhim} />
                </li>
                <li>
                  <img alt="" src={gpay} />
                </li>

                <li>
                  <img alt="" src={paytm} />
                </li>
                <li>
                  <img alt="" src={phonepe} />
                </li>
                <li>
                  <img alt="" src={rupay} />
                </li>
                <li>
                  <img alt="" src={upi} />
                </li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
      <div className="container-fluid px-lg-4 footer-bottom py-2">
        <div className="row flex-column-reverse flex-lg-row">
          <div className="col-12 col-lg-6 text-lg-start text-center">
            Powered by{" "}
            <a href="https://bechofy.in/" target="blank">
              {" "}
              Bechofy
            </a>{" "}
            - <br className="d-block d-md-none" /> A Product of
            <a href="https://globaltrendz.com/" target="blank">
              &nbsp; Global Trendz
            </a>
          </div>
          <div className="col-12 col-lg-6 text-lg-end text-center">
            <p className="mb-0">
              Copyright &copy; {moment().format("YYYY")} Blessings Astrology.
              <br className="d-block d-md-none" /> All Rights Reserved.
            </p>
          </div>
        </div>
      </div>

      {window.location.pathname.split("/")[1] != "book-a-slot" ? (
        <>
          <div class={`floating_btn ${atBottom ? "hide-footer-btn" : ""}`}>
            <a href="https://wa.me/+919821155251" class="whatsapp-btn mb-3">
              <FaWhatsapp />
            </a>
            {/* call now button which is down of whatsapp */}
            <div class=" mb-4 book-now">
              <a onClick={() => showModal()}>
                {/* <FiPhoneCall /> */}
                Book Now
              </a>
            </div>
            <div class=" mb-4 read-more ">
              <a href="/shop-online">
                {/* <FiPhoneCall /> */}
                Shop Online
              </a>
            </div>
          </div>
          {/* <div class="floating_btn">
            <div class="contact_icon">
              <button
                className="button bounce"
                onClick={() => {
                  handleShow(true);
                }}>
                Ask Now
              </button>
            </div>
          </div> */}
        </>
      ) : (
        <></>
      )}
      {/* *** Consult button **** */}
      <Modal
        show={show}
        onHide={handleClose}
        className="d-flex align-items-center"
      >
        <Modal.Header closeButton style={{ zIndex: "999" }}></Modal.Header>
        <Modal.Body>
          <ConsultForm setShow={setShow} />
        </Modal.Body>
      </Modal>
      {showForm && (
        <Appointmentform
          serviceName={serviceTitle}
          showForm={showForm}
          closeModal={closeModal}
        />
      )}
    </>
  );
}
